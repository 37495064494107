<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="false"
      variant="light"
      opacity="0.5"
      :blur="'true'"
      rounded="sm"
    >
      <b-card
        :title="`ข้อมูลโปรโมชั่น`"
      >
        <b-row>
          <b-col
            md="2"
            class="mb-2"
          >
            <h5 class="text-capitalize mb-75">
              เปิดใช้งาน
            </h5>
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="promotionForm.active"
              name="flavour-1"
            >
              <b-form-checkbox
                :value="true"
                switch
              >
                {{ (promotionForm.active || {}).length > 0 ? 'เปิด':'ปิด' }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ชนิดโปรโมชั่น
            </h5>
            <v-select
              v-model="promotionForm.event_type"
              :options="promotionType"
              :reduce="option => option.value"
              label="title"
            />
          </b-col>
          <b-col md="6">
            <h5 class="text-capitalize mb-75">
              ชื่อโปรโมชั่น
            </h5>
            <b-form-input
              id="mc-user-name"
              v-model="promotionForm.detail"
              placeholder="ชื่อโปรโมชั่น"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            md="2"
            class="mb-2"
          >
            <h5 class="text-capitalize mb-75">
              ตั้งช่วงวันที่
            </h5>
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="promotionForm.dateCondition"
              name="flavour-1"
            >
              <b-form-checkbox
                :value="true"
                switch
              >
                {{ (promotionForm.dateCondition || {}).length > 0 ? 'ตั้งวันที่':'ไม่ระบุ' }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col
            v-if="(promotionForm.dateCondition || {}).length > 0"
            md="6"
          >
            <b-form-group
              label="ช่วงวันที่"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <flat-pickr
                    v-model="promotionForm.startdate"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
                <b-col md="6">
                  <flat-pickr
                    v-model="promotionForm.enddate"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        :title="`เงื่อนไข`"
      >
        <b-row class="mt-2" v-if="promotionForm.event_type && ['FIRST-DEPOSIT', 'FIRST-DAILY-DEPOSIT', 'DEPOSIT', 'FIRST-DEPOSIT-L1-CHILD'].indexOf(promotionForm.event_type) >= 0">
          <b-col md="6">
            <b-form-group
              label="จำนวนยอดฝาก"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <b-form-input
                    v-model="promotionForm.condition_min_amount"
                    placeholder="ขั้นต่ำ"
                  />
                </b-col>
                <b-col md="6">
                  <b-form-input
                    v-model="promotionForm.condition_max_amount"
                    placeholder="สูงสุด"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="promotionForm.event_type && ['FIRST-DEPOSIT', 'FIRST-DAILY-DEPOSIT', 'DEPOSIT', 'FIRST-DEPOSIT-L1-CHILD'].indexOf(promotionForm.event_type) < 0">
          <b-col md="12">
            <b-form-group
              label="ค่ายเกมส์ที่มีผล"
              label-for="mc-country"
            >
              <b-row>
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="promotionForm.condition_gp"
                  name="flavour-2"
                >
                  <b-form-checkbox
                    v-for="gameProvider in gameProviders"
                    :key="gameProvider.title"
                    :value="gameProvider.value"
                  >
                    {{ gameProvider.title }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        :title="`การคำนวนจ่ายโปรโมชัน`"
      >
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group
              label="อัตราการจ่าย"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <b-input-group
                      append="%"
                    >
                      <b-input-group-prepend is-text>
                        <b-form-radio
                          v-model="radioPaidOption"
                          value="paid-percent"
                          plain
                          name="radio-input2"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="promotionForm.rate_percent"
                        placeholder="อัตราการจ่าย %"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-input-group
                    append="฿"
                  >
                    <b-input-group-prepend is-text>
                      <b-form-radio
                        v-model="radioPaidOption"
                        value="paid-amount"
                        plain
                        name="radio-input2"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="promotionForm.rate_amount"
                      placeholder="อัตราการจ่าย ฿"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="จ่ายสูงสุด"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <b-input-group
                    append="฿"
                  >
                    <b-form-input
                      v-model="promotionForm.max_paid"
                      placeholder="จ่ายสูงสุด ฿"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        :title="`เงื่อนไขการรับโปรโมชั่น`"
      >
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group
              label="เทิร์นที่ต้องทำ"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <b-input-group
                      append="เท่า"
                    >
                      <b-input-group-prepend is-text>
                        <b-form-radio
                          v-model="radioTurnOption"
                          value="turn-percent"
                          plain
                          name="radio-input1"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="promotionForm.turn_times"
                        placeholder="เทิร์นที่ต้องทำ (เท่า)"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-input-group
                    append="฿"
                  >
                    <b-input-group-prepend is-text>
                      <b-form-radio
                        v-model="radioTurnOption"
                        value="turn-amount"
                        plain
                        name="radio-input1"
                      />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="promotionForm.turn_amount"
                      placeholder="เทิร์นที่ต้องทำ ฿"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="6">
            <b-form-group
              label="จำนวนการรับ ขั้นต่ำ - สูงสุด"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <b-input-group
                      prepend="ขั้นต่ำ"
                      append="฿"
                    >
                      <b-form-input
                        v-model="promotionForm.min_receivable"
                        placeholder="จำนวนขั้นต่ำที่กดรับได้"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-input-group
                    prepend="สูงสุด"
                    append="฿"
                  >
                    <b-form-input
                      v-model="promotionForm.max_receivable"
                      placeholder="จำนวนสูงสุดที่กดรับได้"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        :title="``"
      >
        <b-row class="mt-2">
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="savePromotion(promotionInfo.id, promotionForm, 'loadingProfile')"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BInputGroup, BFormRadio, BInputGroupPrepend, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BCardText, BOverlay, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import VueSelect from '../forms/form-element/vue-select/VueSelect.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    // BBadge,
    flatPickr,
    BFormGroup,
    // BFormSelect,
    BFormRadio,
    BPagination,
    // BCardText,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormCheckbox,
    BFormCheckboxGroup,
    // Cleave,
    vSelect,
    VueSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      promotionType: [
        { title: 'ฝากครั้งแรก', value: 'FIRST-DEPOSIT' },
        { title: 'ฝากครั้งแรกของวัน', value: 'FIRST-DAILY-DEPOSIT' },
        { title: 'ฝากเงิน', value: 'DEPOSIT' },
        { title: 'ฝากครั้งแรกเพื่อนที่ชวนมา', value: 'FIRST-DEPOSIT-L1-CHILD' },
        { title: 'โรลลิ่ง', value: 'ROLLING' },
        { title: 'คืนยอดเสีย', value: 'CASHBACK' },
        { title: 'โรลลิ่ง (เพื่อนชั้นลูก)', value: 'ROLLING-L1-CHILD' },
        { title: 'คืนยอดเสีย  (เพื่อนชั้นลูก)', value: 'CASHBACK-L1-CHILD' },
        { title: 'โรลลิ่ง (เพื่อนชั้นหลาน)', value: 'ROLLING-L2-CHILD' },
        { title: 'คืนยอดเสีย  (เพื่อนชั้นหลาน)', value: 'CASHBACK-L2-CHILD' },
        { title: 'โรลลิ่ง (เพื่อนชั้นเหลน)', value: 'ROLLING-L3-CHILD' },
        { title: 'คืนยอดเสีย  (เพื่อนชั้นเหลน)', value: 'CASHBACK-L3-CHILD' },
      ],
      gameProviders: [
        { title: 'SA Gaming', value: 'SA' },
        { title: 'SEXY', value: 'SEXY' },
        { title: 'WM', value: 'WM' },
        { title: 'DG', value: 'DG' },
        { title: 'CQ9', value: 'CQ9' },
        { title: 'PG', value: 'PG' },
        { title: 'JOKER', value: 'JOKER' },
        { title: 'MG', value: 'MG' },
        { title: 'JDB', value: 'JDB' },
        { title: 'EVOLUTION', value: 'EVOLUTION' },
        { title: 'JILLI', value: 'JILLI' },
        { title: 'KA', value: 'KA' },
        { title: 'PRAGMATIC PLAY', value: 'PP' },
        { title: 'PP SportBook', value: 'PP-SPORT' },
        { title: 'SBO', value: 'SBO' },
        { title: 'HOTGRAPH', value: 'HOTGRAPH' },
        { title: 'LOTTO', value: 'LOTTO' },
      ],
      loadingProfile: false,
      userInput: null,
      isBusy: false,
      localVariables: {},
      pageOptions: [50, 100, 200, 500, 1000],
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalWalletRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 50,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'created_at', label: 'วันที่' },
        { key: 'username', label: 'ยูส/เบอร์' },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวน' },
        { key: 'balance', label: 'คงเหลือ' },
        { key: 'ref2', label: 'อ้างอิง 2' },
        { key: 'ref3', label: 'อ้างอิง 3' },
        { key: 'platform', label: 'ระบบ' },
      ],
      activity_fields: [
        { key: 'date', label: 'วันที่' },
        { key: 'hour', label: 'เวลา (นาฬิกา)' },
        { key: 'stat_type', label: 'ประเภท' },
        { key: 'game', label: 'ค่ายเกมส์' },
        { key: 'ip', label: 'IP' },
      ],
      /* eslint-disable global-require */
      promotionInfo: null,
      promotionForm: {
        active: [true],
        dateCondition: [],
      },
      allpermissions: [],
      mode: 'update',
      userActivities: [],
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    radioTurnOption() {
      if (this.promotionForm.turn_amount === undefined || parseFloat(this.promotionForm.turn_amount || 0) === 0) {
        return 'turn-percent'
      }
      return 'turn-amount'
    },
    radioPaidOption() {
      if (this.promotionForm.rate_amount === undefined || parseFloat(this.promotionForm.rate_amount || 0) === 0) {
        return 'paid-percent'
      }
      return 'paid-amount'
    },
  },
  mounted() {
    if (this.$route.params.promotionId === 'add-new') {
      this.mode = 'add'
      this.promotionInfo = {
        active: [],
      }
      this.promotionForm = {
        active: [],
      }
      this.userActivities = []
    } else if (this.$route.params.promotionId !== null && this.$route.params.promotionId !== undefined && this.$route.params.promotionId !== 'add-new') {
      this.promotionInfo = {
        active: [],
        id: this.$route.params.promotionId,
      }
      this.fetchRecords()
      this.mode = 'update'
    }
    // this.fetchPermission()
  },
  methods: {
    searchMember() {
      this.fetchRecords()
      // this.$refs.receivedPromotionsTable.refresh()
    },
    clearSearch() {
      this.userInput = null
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.receivedPromotionsTable.refresh()
    },
    async fetchPermission() {
      const { data: { success: permSuccess, permissions } } = await this.$http.get('admin/all-permissions')
      this.allpermissions = permissions
    },
    async fetchRecords(ctx) {
      console.log('[fetchRecords]', ctx)
      this.userActivities = []
      const { data: { success, promotion } } = await this.$http.get(`promotions/${this.promotionInfo.id}`)
      this.promotionInfo = { ...promotion, active: promotion.active === 1 ? [true] : [] }
      this.promotionForm = { ...promotion, active: promotion.active === 1 ? [true] : [] }
      return this.walletItems
    },
    async savePromotion(id, changes, loadingRef) {
      this[loadingRef] = true
      if (this.mode === 'update') {
        const allowChanges = ['active', 'event_type', 'condition_gp', 'condition_min_amount', 'condition_max_amount', 'is_active', 'detail', 'max_paid', 'min_receivable', 'max_receivable', 'rate_percent', 'rate_amount', 'turn_amount', 'turn_times']
        const changeMade = {}

        if (this.radioTurnOption === 'turn-amount') {
          this.promotionForm.turn_percent = null
        } else {
          this.promotionForm.turn_amount = null
        }

        if (this.radioPaidOption === 'rate-amount') {
          this.promotionForm.rate_percent = null
        } else {
          this.promotionForm.rate_amount = null
        }

        Object.keys(changes).forEach(keyItem => {
          if (allowChanges.indexOf(keyItem) >= 0 && changes[keyItem] !== this.promotionInfo[keyItem])
            changeMade[keyItem] = changes[keyItem]
          if (keyItem === 'active')
            changeMade[keyItem] = changes[keyItem].length > 0 ? 1 : 0
        })

        try {
          const { data: saveResult } = await this.$http.put(`promotions/${id}`, changeMade)
          this[loadingRef] = false
          if (saveResult.success) {
            this.promotionInfo = { ...saveResult.promotion, active: saveResult.promotion.active ? [true] : [] }
            this.promotionForm = { ...saveResult.promotion, active: saveResult.promotion.active ? [true] : [] }
            this.$bvToast.toast(`โปรโมชั่น ${this.promotionForm.detail}`, {
              variant: 'success',
              title: 'แก้ไขโปรโมชั่นสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            return
          }
          throw new Error(saveResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'แก้ไขโปรโมชั่นไม่สำเร็จ',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      } else {
        const allowChanges = ['active', 'event_type', 'condition_gp', 'condition_min_amount', 'condition_max_amount', 'is_active', 'detail', 'max_paid', 'min_receivable', 'max_receivable', 'rate_percent', 'rate_amount', 'turn_amount', 'turn_times']
        const changeMade = {}

        if (this.radioTurnOption === 'turn-amount') {
          this.promotionForm.turn_percent = null
        } else {
          this.promotionForm.turn_amount = null
        }

        if (this.radioPaidOption === 'rate-amount') {
          this.promotionForm.rate_percent = null
        } else {
          this.promotionForm.rate_amount = null
        }

        Object.keys(changes).forEach(keyItem => {
          if (allowChanges.indexOf(keyItem) >= 0 && changes[keyItem] !== this.promotionInfo[keyItem])
            changeMade[keyItem] = changes[keyItem]
          if (keyItem === 'active')
            changeMade[keyItem] = changes[keyItem].length > 0 ? 1 : 0
        })

        try {
          const { data: saveResult } = await this.$http.post('promotions', changeMade)
          this[loadingRef] = false
          if (saveResult.success) {
            this.promotionInfo = { ...saveResult.promotion, active: saveResult.promotion.active ? [true] : [] }
            this.promotionForm = { ...saveResult.promotion, active: saveResult.promotion.active ? [true] : [] }
            this.$bvToast.toast(`โปรโมชั่น ${this.promotionForm.detail}`, {
              variant: 'success',
              title: 'แก้ไขโปรโมชั่นสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            return
          }
          throw new Error(saveResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'แก้ไขโปรโมชั่นไม่สำเร็จ',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      }
    },
    async saveProfile(id, changes, loadingRef) {
      this[loadingRef] = true
      if (this.mode === 'update') {
        const allowChanges = ['phoneno', 'name', 'permission', 'password', 'is_active']
        const changeMade = {}
        Object.keys(changes).forEach(keyItem => {
          if (allowChanges.indexOf(keyItem) >= 0 && changes[keyItem] !== this.promotionInfo[keyItem])
            changeMade[keyItem] = changes[keyItem]
          if (keyItem === 'is_active')
            changeMade[keyItem] = changes[keyItem].length > 0
        })

        try {
          if (changeMade.phoneno)
            changeMade.phoneno = changeMade.phoneno.replace(/-/ig, '')
          const { data: saveProfileResult } = await this.$http.put(`admin/${id}`, changeMade)
          this[loadingRef] = false
          if (saveProfileResult.success) {
            this.promotionInfo = { ...saveProfileResult.staff, is_active: saveProfileResult.staff.is_active ? [true] : [] }
            this.promotionForm = { ...saveProfileResult.staff, is_active: saveProfileResult.staff.is_active ? [true] : [] }
            this.$bvToast.toast(`แอดมิน ${this.promotionForm.phoneno} ${this.promotionForm.name}`, {
              variant: 'success',
              title: 'แก้ไขแอดมินสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            return
          }
          throw new Error(saveProfileResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'แก้ไขแอดมินผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      } else if (this.mode === 'add') {
        const changeMade = {}
        Object.keys(changes).forEach(keyItem => {
          if (keyItem === 'is_active')
            changeMade[keyItem] = changes[keyItem].length > 0
          else
            changeMade[keyItem] = changes[keyItem]
        })
        try {
          if (changeMade.phoneno)
            changeMade.phoneno = changeMade.phoneno.replace(/-/ig, '')
          const { data: saveProfileResult } = await this.$http.post('admin', changeMade)
          this[loadingRef] = false
          if (saveProfileResult.success) {
            this.promotionInfo = { ...saveProfileResult.staff }
            this.promotionForm = { ...saveProfileResult.staff }
            this.$bvToast.toast(`แอดมิน ${this.promotionForm.phoneno} ${this.promotionForm.name}`, {
              variant: 'success',
              title: 'เพิ่มแอดมินสำเร็จ',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: false,
            })
            this.$router.replace({ name: 'manage-admin-detail', params: { admin: saveProfileResult.staff.phoneno } })
            return
          }
          throw new Error(saveProfileResult.error_message || '')
        } catch (err) {
          console.log(err)
          this.$swal({
            title: 'เพิ่มแอดมินผิดพลาด',
            text: err,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-info',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
        }
      }
      this[loadingRef] = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
